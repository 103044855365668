import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { parseAsText } from "utils/parser"

const Seo = ({ title, description, canonical, image, pageContext }) => {
  let seo = {}
  const staticData = useStaticQuery(graphql`
    query SeoGlobalQueryStatic {
      craft {
        # Globals
        globalsDe: globals(siteId: 1) {
          ...GlobalSeoQuery
        }

        globalsEn: globals(siteId: 2) {
          ...GlobalSeoQuery
        }
      }
    }
  `)

  const { globalsDe, globalsEn } = staticData?.craft

  const globals = pageContext.siteId === 1 ? globalsDe : globalsEn
  const { defaultTitleTemplate, defaultTitle, defaultImage } = globals?.seo

  if (pageContext) {
    seo = {
      title:
        title ||
        pageContext?.entry?.seoTitle ||
        parseAsText(pageContext?.entry.title) ||
        defaultTitle,
      description: description || pageContext?.entry?.seoDescription || null,
      canonical: canonical || pageContext?.entry?.seocanonicalTag || null,
      image:
        image?.[0] || pageContext?.entry?.seoImage?.[0] || defaultImage?.[0],
    }
  } else {
    seo = {
      title: title || defaultTitle,
      description,
      canonical,
      image: image?.[0] || defaultImage?.[0],
    }
  }

  return (
    <Helmet
      title={seo.title}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitleTemplate}>
      /!* Meta tags *!/
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.image && <meta name="image" content={seo.image.optimizedUrl} />}
      {seo.canonical && <link rel="canonical" href={seo.canonical} />}
      <meta
        name="facebook-domain-verification"
        content="3evp74oe8bzdjaui3yob79wtw5stoy"
      />
      /!* Open Graph *!/
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && (
        <meta property="og:image" content={seo.image.optimizedUrl} />
      )}
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  image: PropTypes.array,
  pageContext: PropTypes.object,
}

Seo.defaultProps = {
  title: null,
  description: null,
  canonical: null,
  image: null,
  pageContext: null,
}

export default Seo

export const query = graphql`
  fragment GlobalSeoQuery on Craft_GlobalsSet {
    seo {
      defaultTitleTemplate: seoTitleTemplate
      defaultTitle: seoTitle
      defaultDesc: seoDescription
      defaultImage: seoImage {
        optimizedUrl: url(crop: { width: 1080, quality: 90 })
      }
    }
  }
`
