import React from 'react';
import { graphql } from 'gatsby';

import PageBuilder from 'components/PageBuilder/PageBuilder';
import Wrap from 'components/Wrap';
import Seo from 'components/Seo';

export default function TemplateError404({ data, pageContext }) {
  const { entry } = data?.craft;
  const { seoTitle, seoDescription, seoImage } = entry;

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        defaults={data?.craft?.globals?.seo}
        pageContext={pageContext} 
      />
      <Wrap minHeight="100vh">
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          pageContext={pageContext}
        />
      </Wrap>
    </>
  );
}

export const query = graphql`
  query TemplateError404($id: [Int]!, $siteId: Int!) {
    craft {
      # Globals
      globals(siteId: $siteId) {
        ...GlobalNavigationSocialQuery
        ...GlobalCtaSignUpQuery
        ...GlobalFooterSectionQuery
        ...GlobalCookieInfoQuery
        ...GlobalSeoQuery
      }

      # NavigationHeader
      navigationHeaderItems: entries(
        section: navigationHeader
        siteId: $siteId
      ) {
        ...NavigationHeaderQuery
      }

      # NavigationFooter
      navigationFooterItems: entries(
        section: navigationFooter
        siteId: $siteId
      ) {
        ...NavigationFooterQuery
      }

      # Single: error 404
      entry(id: $id, siteId: $siteId) {
        ... on Craft_Error404 {
          seoTitle
          seoDescription
          seoImage {
            optimizedUrl: url(crop: { width: 1080, quality: 90 })
          }
          pageBuilder {
            ...PageBuilderQuery
          }
        }
      }
    }
  }
`;
